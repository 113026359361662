main{
  #intro{
    padding-top: 130px;
    color: $brand-primary;
    .container{
      h2{
        color: $brand-primary;
        font-size: 4rem;
        font-weight: 700;
      }
      p{
        color: $brand-primary;
        font-size: 1.5rem;
        font-weight: 100;
        letter-spacing: -1px;
      }
    }
  }

  #place{
    width: 100%;
    color: $brand-primary;
    padding: 20px 0;
    .container{
      .row{
        .left{
          background: #e8e7e7;
          background: -moz-linear-gradient(left, #e8e7e7 0%, #ebeaea 100%);
          background: -webkit-linear-gradient(left, #e8e7e7 0%,#ebeaea 100%);
          background: linear-gradient(to right, #e8e7e7 0%,#ebeaea 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e7e7', endColorstr='#ebeaea',GradientType=1 );
          .d-logo {
            background: url("../images/3d-logo.png") no-repeat;
            display: block;
            background-size: 200px 200px;
            max-width: 200px;
            height: 200px;
            margin: 0 auto;
          }
        }
        .right{
          .place-picker{
            background: url("../images/place-picker.svg") no-repeat;
            background-size: contain;
            width: 66px;
            height: 97px;
            display: block;
          }
          p{
            font-size:1.375rem;
            font-weight: 100;
            strong{
              font-size:1.625rem;
              font-weight: 700;
            }
            &:after{
              display: block;
              content: "";
              background: $brand-info;
              width: 70px;
              height: 11px;
              margin: 30px auto;
            }
          }

          .d-block{
            span{
              border: 1px solid $brand-primary;
              font-size: 1rem;
              display: block;
            }
          }
        }
      }
    }
  }

  #form{
    padding:20px 0;
    color: $brand-primary;
    .guide{
      h4{
        font-size: 1.875rem;
        font-weight: 100;
        color: $brand-primary;
        &:after{
          display: block;
          content: "";
          background: $brand-info;
          width: 70px;
          height: 11px;
          margin: 30px auto;
        }
      }
      p{
        font-size: 1.375rem;
        color:$brand-primary;
        letter-spacing: -.5px;
        line-height: 2rem;
        max-width: 800px;
      }
    }

    form{
      color: #fff;
      background: rgba(42, 44, 45, 0.9);;
      padding: 40px 15px;
      h3{
        font-weight: 100;
        font-size: 1.5rem;
        &:after{
          display: block;
          content: "";
          background: $brand-info;
          width: 70px;
          height: 11px;
          margin: 30px auto;
        }
      }

      label{
        font-size: 1.125rem;
        line-height: 1.5rem;
      }

      label.error{
        color: $brand-danger;
        font-size: 1rem;
      }

      input{
        background: #EFEFEB;
      }

      button{
        color: #fff;
        border: 2px solid #EFEFEB;
        font-size: 1.125rem;
        line-height:1.5rem;
        font-weight: 700;
        border-radius: 15px;
        margin: 20px;
        box-shadow: 0 2px 10px black;
      }

      small{
        font-size: 1rem;
        line-height: 1.5625rem;
        font-weight: 100;
      }

      ::-webkit-input-placeholder {
        color: $brand-primary;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      ::-moz-placeholder {
        color: $brand-primary;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      :-ms-input-placeholder {
        color: $brand-primary;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      :-moz-placeholder {
        color: $brand-primary;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}

@media (min-width: 544px) {

}
@media (min-width: 768px) {
  main{
    #intro{
      padding-top: 180px;
      //padding-bottom: 40px;
      .container{
        h2{
          font-size:2.75rem;
          line-height: 1.2em
        }
      }
    }

    #place{
      background: #e8e7e7;
      background: -moz-linear-gradient(left, #e8e7e7 0%, #ebeaea 100%);
      background: -webkit-linear-gradient(left, #e8e7e7 0%,#ebeaea 100%);
      background: linear-gradient(to right, #e8e7e7 0%,#ebeaea 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e7e7', endColorstr='#ebeaea',GradientType=1 );
      padding: 80px 0;
      .container{
        .row{
          .left{
            background: none !important;
            .d-logo{
              background-size: 400px 400px;
              max-width: 400px;
              height: 400px;
              margin-left: -40px;
            }
          }
          .right{
            .d-block{
              span{
                display: inline-block;
              }
            }
          }
        }
      }

    }

    #form{
      background: url("../images/watch-white-bg.jpg") no-repeat;
      //background-size: cover;
      background-position-y: 200%;
      padding:80px 0;

      form{
        padding: 60px 90px;
        h3{
          font-size: 1.875rem;
        }
      }
    }
  }
}
@media (min-width: 992px) {}
@media (min-width: 1200px) {}

.frame-wrapper {
    position: relative;
    display: block;
    @media screen and (min-width: 768px) {
        margin-bottom: 40px;
    }

    &:after {
        content: '';
        display: block;
        width: 150px;
        height: 35px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 3;
    }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: $brand-info;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;

  &.loaded {
    background-color: green;

    &:after {
        content: '✔';
        display: inline;
        color: white;
        margin-left: 10px;
    }
  }
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.strong {
    font-weight: bold;
}

.spacer {
    display: block;
    content: "";
    background: #b79767;
    width: 70px;
    height: 11px;
    margin: 50px auto;
}

@media screen and (max-width: 767px) {
    .banner .container .brand {
        background-position: 50% 50%;
        height: 65px;
    }

    .banner.photo {
        & .container .brand {
            margin-bottom: 0 !important;
        }
        & .container .watch {
            display: none !important;
        }
    }

    main #intro.photo {
        padding-top: 30px;
    }
}

.col-md-8.flex {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
    .banner .container .brand {
        margin-bottom: 0 !important;
    }

    #center-watch,
    #right-watch,
    #left-watch,
    .mouse-scroll,
    .place-anchor,
    #place {
        display: none !important;
    }

    main #intro {
        padding-top: 20px;
    }

    main #intro .container h2 {
        font-size: 1.5rem;
    }
    main #intro .container p {
        font-size: 1rem;
    }

    .frame-wrapper {
        margin-bottom: 20px;
    }

    main #form .guide p {
        font-size: 1em;
    }
}

.how-to {
  counter-reset: steps;

  & * {
    box-sizing: border-box;
  }

  margin: 0 0 100px 0;
  padding: 0;
  list-style: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-flow: column;
  }

  & li {
    counter-increment: steps;
    margin: 0 0 30px 0;

    @media screen and (min-width: 769px) {
      margin: 0 30px 0 0;
    }
    &:last-of-type {
      margin: 0;
    }

    padding: 0;

    & div {
      display: flex;
      &:before {
        content: counter(steps);
        font-size: 50px;
        line-height: 1;
        height: auto;
        font-weight: 700;
        margin-right: 20px;
        color: #b79767;
      }
      border-radius: .3rem;
      padding: 30px;
      background: $brand-primary;
      color: white;
    }

    &:nth-child(1) div {
      opacity: .8;
    }
    &:nth-child(2) div {
      opacity: .9;
    }
    &:nth-child(3) div {
      opacity: 1;
    }
  }
}
