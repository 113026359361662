.content-info{
  color: #fff;
  width: 100%;
  float: left;
  padding: 40px 0;
  font-size: .8125rem;
  .container{
    .row{
      .logo-footer{
        h2{
          a{
            text-indent: -9999px;
            background: url('../images/logo.svg') no-repeat;
            width: 65px;
            height: 65px;
            display: block;
            background-size: 65px 65px;
            margin: 0 auto;
          }
        }
      }
      .credits{
        p{
          border-bottom: 1px solid #fff;
        }
        nav{
          ul{
            li{
              display: inline-block;
              a{
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 544px) {}
@media (min-width: 768px) {
  .content-info{
    padding: 40px 0 80px;
    font-size: .9375rem;
  }

}
@media (min-width: 992px) {}
@media (min-width: 1200px) {}