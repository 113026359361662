.banner{
  background: url("../images/header-bg_dark.jpg") no-repeat top center fixed;
  background-size: auto;
  color: #fff;
  position: relative;
  padding-bottom: 40px;

  .container{
    .brand{
      background: url("../images/logo@4x.png") no-repeat center center;
      background-size: contain;
      text-indent: -9999px;
      width: 100%;
      height: 105px;
      display: block;
    }
    .hero{
      font-size: .9rem;
      letter-spacing: 2.9px;
      font-weight: 400;
      &:after{
        display: block;
        content: "";
        background: $brand-info;
        width: 70px;
        height: 11px;
        margin: 30px auto;
      }
    }

    .watch{
      display: block;
      position: absolute;
    }

    #left-watch{
      background: url("../images/left-watch.png") no-repeat;
      background-size: 169px 94.5px;
      width: 169px;
      height: 94.5px;
      left:24%;
      margin-left: -84.5px;
      bottom:-128px;
    }

    #center-watch{
      background: url("../images/center-watch.png") no-repeat;
      width: 212px;
      height: 120.5px;
      background-size: 212px 120.5px;
      z-index: 99;
      left:50%;
      margin-left: -106px;
      bottom:-170px;
    }

    #right-watch{
      background: url("../images/right-watch.png") no-repeat;
      width: 170px;
      height: 96px;
      background-size: 170px 96px;
      left:77%;
      margin-left: -85px;
      bottom:-122px;
    }
  }
}

@media (min-width: 544px) {}
@media (min-width: 768px) {
  .banner{
    padding-bottom: 30px;
    .container{
      #left-watch{
        background-size: 270px 151px;
        width: 270px;
        height: 151px;
        left:24%;
        margin-left: -42px;
        bottom:-156px;
      }

      #center-watch{
        width: 339px;
        height: 193px;
        background-size: 339px 193px;
        z-index: 99;
        left:50%;
        margin-left: -143px;
        bottom:-220px;
      }

      #right-watch{
        width: 272px;
        height: 154px;
        background-size: 272px 154px;
        left:77%;
        margin-left: -206px;
        bottom:-162px;
      }
    }
  }
}
@media (min-width: 992px) {}
@media (min-width: 1200px) {
  .banner{
    background-size: contain;
  }
}
