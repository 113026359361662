body{
  font-family: 'Montserrat', sans-serif;
}

@-webkit-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 30%;
  }
  25% {
    opacity: 1;
    top: 25%;
  }
  50% {
    opacity: 1;
    top: 50%;
  }

  75% {
    opacity: 1;
    top: 55%;
  }

  100% {
    opacity: 1;
    top: 30%;
  }
}
@-moz-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 1;
    top: 50%;
  }
  100% {
    opacity: 1;
    top: 29%;
  }
}
@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }
  15% {
    opacity: 1;
    top: 50%;
  }
  50% {
    opacity: 1;
    top: 50%;
  }
  100% {
    opacity: 1;
    top: 29%;
  }
}


.mouse-scroll{
  display: block;
  width: 45px;
  height:182px;
  position: relative;
  .line-up{
    background: url("../images/line.svg") no-repeat;
    background-size: 2px 48px;
    width: 2px;
    height: 48px;
    display: block;
    position: absolute;
    left:50%;
    margin-left: -1px;
  }

  .mouse{
    background: url("../images/mouse.svg") no-repeat;
    background-size: 45px 65px;
    width: 45px;
    height: 65px;
    display: block;
    position: absolute;
    top:10px;
    .mouse-scroller{
      background: url("../images/mouse-scroller.svg") no-repeat;
      background-size: 7px 13px;
      width: 7px;
      height: 13px;
      display: block;
      position: absolute;
      left:50%;
      margin-left: -3.5px;
      -webkit-animation: ani-mouse 1.5s linear infinite;
      -moz-animation: ani-mouse 1.5s linear infinite;
      animation: ani-mouse 1.5s linear infinite;
    }
  }

  .line-down{
    background: url("../images/line.svg") no-repeat;
    background-size: 2px 48px;
    width: 2px;
    height: 48px;
    display: block;
    position: absolute;
    left:50%;
    margin-left: -1px;
    top:132px;
  }
}